/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from "react";
import { Form, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { withRouter } from '../components/Routing/withRouter';
import AccountService from "../services/account.service";

import "./Login.css";
import { SetPageViewTitle, Event } from "../components/GoogleAnalytics";
import experimentsService from "../services/experiments.service";
import loginService from "../services/login.service";

const jsUser = {
    username: "",
    password: ""
};

class Login extends Component {
    static displayName = Login.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onLogon = this.onLogon.bind(this);

        this.state = {
            logedOnUser: jsUser,
            newLogon: jsUser,
            showMessage: false,
            message: "",
            btnClicked: false,
            showResetPasswordConfirm: false
        }

        //document.addEventListener("keydown", this._handleKeyDown);

        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        SetPageViewTitle('login');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleKeyDown(evt) {        
        evt = evt || window.event;
        if (evt.keyCode === 13) {
            if (this.state.newLogon.username !== "" && this.state.newLogon.password !== "") {
                Event("Login", "User pressed Enter in login field", "Login Clicked");
                this.setState({ btnClicked: true });
                this.onLogon(evt);
            }
            evt.preventDefault();
        }
    }

    validateForm() {
        return this.state.newLogon.username.length > 0 && this.state.newLogon.password.length > 0;
    }

    async onLogon(e) {
        if (this.state.newLogon.username !== "" && this.state.newLogon.password !== "") {
            await loginService.login(this.state.newLogon).then(async response => {
                await AccountService.logon(this.state.newLogon).then(logonResponse => {
                    if (logonResponse.data != null && logonResponse.data !== undefined && logonResponse.data.isLoggedIn === true) {
                        this.props.onLogonChange(logonResponse.data);
                    }
                    else {
                        this.handleLoginFailure();                                                                     
                    }
                }).catch(e => {
                    this.handleLoginFailure();
                });
            })
            .catch(e => {
                this.handleLoginFailure();
            });
        }
    }
    
    handleLoginFailure() {
        this.getForceResetPasswordsExperiment().then(experimentResponse => {
            if (experimentResponse.status === 200 && experimentResponse.data !== null && experimentResponse.data === true) {
                this.setState({
                    showResetPasswordConfirm: true,
                    btnClicked: false
                });
            }
            else {
                this.showLoginFailureMessage();
            }
        }).catch(e => {
            console.log(e);
            this.showLoginFailureMessage();
        });
    }

    async getForceResetPasswordsExperiment() {
        return await experimentsService.getForceResetPasswordsExperiment();
    }

    showLoginFailureMessage() {
        this.setState({ message: "Failed to log in!", btnClicked: false });

        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);
    }

    async onLoginClick(e) {
        Event("Login", "User clicked Log In button", "Login Clicked");
        this.setState({ btnClicked: true });
        this.onLogon(e);
    }

    onUsernameChange(e) {
        const username = e.target.value;

        this.setState(prevState => ({
            newLogon: {
                ...prevState.newLogon,
                username: username
            }
        }));
    }

    onPasswordChange(e) {
        const password = e.target.value;

        this.setState(prevState => ({
            newLogon: {
                ...prevState.newLogon,
                password: password
            }
        }));
    }

    onCancelClick() {
        this.setState({
            showResetPasswordConfirm: false
        });
    };

    render() {
        let loginBtn = this.state.btnClicked ?
            <div>
            <div className="loading" style={{ display: "block", marginLeft: '0px', paddingRight: '0px', width: '100%' }}>
                <div className="loading-wrapper loading-wrapper-login" style={{ display: "block", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                    </div>
                </div>
                <input type="button" disabled={true} onClick={e => { this.onLoginClick(e); }} value="Login" className="btn-wide primary-btn btn-medium" />
            </div>
            : <input type="button" disabled={!this.validateForm()} onClick={e => { this.onLoginClick(e); }} value="Login" className="btn-wide primary-btn btn-medium" />

        return (

            <div style={{ textAlign: "center" }}>

                <div className="modal" style={{ display: this.state.showResetPasswordConfirm ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.state.showResetPasswordConfirm ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.state.showResetPasswordConfirm ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Reset Password</h3>
                            <span className="close-modal-btn" onClick={() => { this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "center" }}>
                            <div className="row" style={{ justifyContent: "center", margin: "0.5em" }}>
                                Your password has expired. Please reset your password.                                                                                           
                            </div>                            
                        </div>


                        <div className="modal-footer">                            
                            <button className="primary-btn btn-small" onClick={this.props.onForgotPassword}>Reset Password</button>
                        </div>
                    </div>
                </div>                

                <img src={require('../img/resideo-black-2.png')} width="250" height="100" alt="LifeWhere" style={{maxHeight: 100 + "px", height: "auto"}} className="fusion-standard-logo fusion-logo-2x" />
                <h2>Pro-IQ&reg; LifeWhere</h2>

                <div className="Login" style={{ textAlign: "center" }}>
                    <Form>
                        <FormGroup controlId="email" >
                            <FormLabel>Username</FormLabel>
                            <FormControl
                                autoFocus
                                type="email"
                                value={this.state.newLogon.username}
                                onChange={e => { this.onUsernameChange(e); }}
                                placeholder="email@address.com"
                                onKeyDown={e => { this._handleKeyDown(e) }}
                            />
                        </FormGroup>
                        <FormGroup controlId="password" >
                            <FormLabel>Password</FormLabel>
                            <FormControl 
                                value={this.state.newLogon.password}
                                onChange={e => { this.onPasswordChange(e); }}
                                type="password"
                                placeholder="password"
                                onKeyDown={e => { this._handleKeyDown(e) }}
                            />
                        </FormGroup>
                        <div>{loginBtn}</div>
                        <button className="tertiary-btn" onClick={this.props.onForgotPassword}>Forgot Password?</button>
                        <span>{this.state.message}</span>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(Login)

