import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, {useEffect, useState} from "react";
import "../../custom.css";
import {Event, PageView} from "../GoogleAnalytics";
import {CardActionArea} from "@mui/material";
import {useNavigate} from "react-router-dom";

function InstallRates({ partnerInfoObj, isLoading, setActivePath }) {

    const history = useNavigate();
    
    const [completedPercentage, setCompletedPercentage] = useState(0.0);
    const [inProgressPercentage, setInProgressPercentage] = useState(0.0);
    
    const [completedTotal, setCompletedTotal] = useState(0);
    const [inProgressTotal, setInProgressTotal] = useState(0);

    const completed = partnerInfoObj?.installs?.firstTimeInstallRates?.completed
    const inProgress = partnerInfoObj?.installs?.firstTimeInstallRates?.inProgress;

    useEffect(()=> {
        if (completed !== undefined && inProgress !== undefined) {
            calculateInstallRates();    
        }
    }, [completed, inProgress]);
    
    const calculateInstallRates = () => {
        try {
            const completedCount = completed !== undefined ? completed : 0;
            const inProgressCount = inProgress !== undefined ? inProgress : 0;
            const totalFirstTimeInstalls = completedCount + inProgressCount;

            setCompletedTotal(completedCount);
            setInProgressTotal(inProgressCount);
            
            setCompletedPercentage(completedCount > 0 ? parseFloat((completedCount / totalFirstTimeInstalls * 100).toFixed(1)) : 0.0);
            setInProgressPercentage(inProgressCount > 0 ? parseFloat((inProgressCount / totalFirstTimeInstalls * 100).toFixed(1)) : 0.0);
        } catch (e) {
            console.log(e);
        }
    }

    const onFirstTimeInstallRatesClick = (currentRadio) => {
        Event("First Time Install Rates tile clicked", "User clicked first time install rates tile to go to provisioning page", "First Time Install Rates tile clicked");
        setActivePath("/installReport");

        const location = {
            pathname: "/installReport",
            state: {
                currentRadio: currentRadio
            }
        }
        
        history(location);
    }

    return (
        isLoading ?
            <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                <div className="col">
                    <Card elevation={4} style={{height: '125px'}}>
                        <CardContent style={{padding: '10px 0px'}}>
                            <h6 style={{paddingLeft: '15px'}}>First Time Install Rates</h6>
                            <div className='row no-gutters card-row centered' style={{minHeight: '52px'}}>
                                <div className="dot-flashing"></div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            :
            <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                <div className="col">
                    <Card elevation={4} style={{height: '125px'}}>
                        <CardActionArea style={{ height: '100%' }}>
                            <CardContent style={{padding: '10px 0px'}}>
                                <h6 style={{paddingLeft: '15px', marginBottom: '0px'}}>First Time Install Rates</h6>
                                <div style={{textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>
                                    <div className="row no-gutters card-row"
                                         style={{textAlign: 'center', margin: '10px 0px'}}>
                                        <div className="col" onClick={() => onFirstTimeInstallRatesClick("NewInstalls")}>
                                            <div className="dashboard-val">
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {`${completedPercentage}% (${completedTotal})`}
                                                </div>
                                                <span style={{color: "dimGray"}}>Completed</span>
                                            </div>
                                        </div>
                                        <div className="col" style={{borderLeft: '1px solid lightGray'}} onClick={() => onFirstTimeInstallRatesClick("InProgress")}>
                                            <div className="dashboard-val">
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {`${inProgressPercentage}% (${inProgressTotal})`}
                                                </div>
                                                <span style={{color: "dimGray"}}>Incomplete</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>
    );
}

export default InstallRates;
